import React from 'react';
import PropTypes from 'prop-types';

import { SHOP_PAGE_PATHNAME } from '../../../lib/CONST';

import List from './List';
import DropDown from './DropDown';

const classes = {
  title: 'text-base font-normal leading-normal text-center text-gray-500 m-0 p-0 pt-1',
};

const ShopMenuList = ({
  categories, pathname, titles, listProps,
}) => (
  <DropDown listProps={listProps}>
    <div className="w-full flex flex-wrap box-border">
      <div className={`m-0 box-border ${categories.length === 2 ? 'w-8/12' : 'w-full'}`}>
        {titles.length > 0 ? (
          <p className={classes.title}>
            {titles[0]}
          </p>
        ) : null}
        <div className="w-full flex flex-wrap box-border">
          <List
            categories={categories[0]}
            shopPathname={pathname}
            column={pathname === SHOP_PAGE_PATHNAME ? 6 : 4}
          />
        </div>
      </div>
      {categories.length === 2 ? (
        <div className="m-0 box-border w-4/12">
          <p className={classes.title}>
            {titles[1]}
          </p>
          <div className="w-full flex flex-wrap box-border">
            <List categories={categories[1]} shopPathname={pathname} />
          </div>
        </div>
      ) : null}
    </div>
  </DropDown>
);

ShopMenuList.propTypes = {
  categories: PropTypes.array.isRequired,
  listProps: PropTypes.object.isRequired,
  titles: PropTypes.array,
  pathname: PropTypes.string.isRequired,
};

ShopMenuList.defaultProps = {
  titles: [],
};

export default ShopMenuList;
