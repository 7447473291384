import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

const ShopMenuList = ({ listProps, children }) => {
  const [hover, setHover] = useState(false);

  return (
    <Popper
      open={listProps.hover || hover}
      anchorEl={listProps.anchorEl}
      placement="bottom-start"
      transition
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => setHover(false)}
      className="mt-3 z-[1500]"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className="pt-2 max-w-4xl min-w-[120px]">{children}</Paper>
        </Fade>
      )}
    </Popper>
  );
};

ShopMenuList.propTypes = {
  listProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default ShopMenuList;
